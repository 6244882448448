@import "../../styles/_fonts.scss";

.button {

  --btn-fg-color: var(--color-dark-500);

  all: unset;
  padding: 5px 8px;
  color: var(--btn-fg-color);
  border-radius: 90px;
  border: 1px solid #DFE1E6;

  user-select: none;

  @include font-pill-button-regular;

  transition: all .12s ease-out;

  &[data-active] {
    --btn-fg-color: var(--color-light-100);

    background: var(--color-primary-100);
    border: none;
  }

  &:hover{
    cursor: pointer;
  }

  &:not([data-active]):hover {
    background: rgba(140, 146, 235, 0.15);
  }
}
