@import "../../styles/_fonts.scss";

.preview {

  --preview-fg-color: var(--color-dark-500);

  padding-right: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;

  p {
    color: var(--preview-fg-color);
    width: 70%;
    line-height: 20px;
    @include font-pill-button-regular;
  }

  &::-webkit-scrollbar{
    width: 4px;
  }

  &::-webkit-scrollbar-thumb{
    background: #DFE1E6;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover{
    background: rgba(76, 87, 118, 0.8);
  }
}

.title {
  --modal-fg-color: var(--color-dark-500);

  color: var(--modal-fg-color);
  font-size: 18px;
  font-weight: 700;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  color: var(--color-dark-500);
}

.tags {
  display: flex;
  gap: 8px;
}
