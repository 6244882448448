@import "../../styles/_fonts.scss";

.diagram {

  --diagram-fg-color: var(--color-dark-500);
  --diagram-bg-color: var(--color-light-200);

  padding: 16px;
  background: var(--diagram-bg-color);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--diagram-fg-color);

  @include font-pill-button-regular;
}

.level {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 24px;
}

.arrow {
  display: flex;
  gap: 8px;
  align-items: center;
}
