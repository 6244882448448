.background {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.center {
  z-index: 999;
  height: 90%;
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1312px;
  min-width: 500px;
}

.modal {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  z-index: 10;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  padding: 16px;
  background-color: #F4F5F7;
  align-items: center;
  justify-content: space-between;

  span {
    color: #4C5776;
    font-size: 16px;
    line-height: 24px;
  }
}

.content {
  height: 100%;
  max-height: 80vh; // KEY
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
}