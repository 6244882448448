.filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.keyFilter {
  width: 100%;
  max-width: 780px;
  padding-bottom: 8px;
  display: flex;
  gap: 24px;
  overflow-x: auto;

  &::-webkit-scrollbar{
    height: 6px;
  }

  &::-webkit-scrollbar-thumb{
    background: #DFE1E6;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover{
    background: rgba(76, 87, 118, 0.8);
  }
}

.content {
  display: grid;
  overflow: hidden;
}

.onPreview {
  grid-template-columns: 1fr 2fr;
  gap: 24px;
}


.templateCards {

  --modal-fg-color: var(--color-dark-500);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;

  span {
    color: var(--modal-fg-color);
    font-size: 18px;
    font-weight: 700;
  }
}

.cards {
  padding-right: 16px;
  padding-bottom: 9px;
  max-height: 480px;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 100px;
  overflow-y: auto;
  
  &::-webkit-scrollbar{
    height: 6px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb{
    background: #DFE1E6;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover{
    background: rgba(76, 87, 118, 0.8);
  }
}

// ::-webkit-scrollbar-track{
//   margin-inline: 8px;
// }
