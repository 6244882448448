.icon {
  position: relative;
  padding: 5px;
  height: 15px;
  width: 15px;
}

.cross {
  height: 15px;
  width: 15px;
  position: absolute;
  opacity: 0.3;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:before, &:after {
    position: absolute;
    content: '';
    height: 18px;
    width: 2px;
    border-radius: 1px; 
    background-color: #092232;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
