@import './_colors.scss';
@import './_fonts.scss';

:root {
  @include font-base;
  @include colors-base;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
