@import "../../styles/_fonts.scss";

.card {
  --crd-bg-color: var(--color-light-100);
  --crd-title-color: var(--color-dark-600);
  --crd-desc-color: var(--color-dark-500);
  --bor-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: var(--crd-bg-color);
  border-radius: var(--bor-radius);
  border: 1px solid #dfe1e6;

  user-select: none;

  font-size: 14px;
  line-height: 20px;

  transition: all 0.12s ease-out;

  span {
    color: var(--crd-title-color);
    @include font-card-regular;
  }

  p {
    color: var(--crd-desc-color);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &[data-active] {
    background: var(--color-light-300);
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #ada8ff;
  }

  &:active {
    background: var(--color-light-300);
  }
}
