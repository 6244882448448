@mixin font-base {
  // should be applied to :root

  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}


@mixin font-body-regular {
  // nothing to change
}

@mixin font-body-bold {
  font-weight: 600;
}

@mixin font-paragraph-regular {
  line-height: 1.5;
}

@mixin font-paragraph-bold {
  line-height: 1.5;
  font-weight: 600;
}


@mixin font-button-regular {
  font-size: 14px;
  font-weight: 600;
}

@mixin font-button-small {
  font-size: 12px;
}

@mixin font-card-regular {
  font-size: 14px;
  font-weight: 400;
}

@mixin font-pill-button-regular {
  font-size: 14px;
  font-weight: 400;
}

@mixin font-searchbar-regular {
  font-size: 12px;
}
