@mixin colors-base {
  --color-primary-300: #2d39c8;
  --color-primary-200: #4853d6;
  --color-primary-100: #8c93eb;

  --color-accent-300: #f02653;
  --color-accent-200: #f95176;
  --color-accent-100: #ff8aa4;

  --color-dark-600: #092232;
  --color-dark-500: #4c5776;
  --color-dark-400: #b3bac5;

  --color-light-300: #eef0ff;
  --color-light-200: #f9f9fb;
  --color-light-100: #ffffff;

  --color-error-400: #b92500;
  --color-error-300: #ff5630;
  --color-error-200: #ffbdad;
  --color-error-100: #ffebe6;

  --color-warning-400: #ff8b00;
  --color-warning-300: #ffab00;
  --color-warning-200: #ffe380;
  --color-warning-100: #fffae6;

  --color-success-400: #006644;
  --color-success-300: #36b37e;
  --color-success-200: #abf5d1;
  --color-success-100: #e3fcef;
}
