@import "../../styles/_fonts.scss";

.searchBar {
  --icon-height: 18px;

  --left-padding: 16px;

  &[data-lefticon] {
    --left-padding: 12px;
  }

  --right-padding: 16px;
  &[data-righticon] {
    --right-padding: 12px;
  }

  padding: 0 var(--right-padding) 0 var(--left-padding);
  display: inline-flex;
  align-items: center;
  height: 32px;
  width: 100%;
  max-width: 50%;
  background: var(--color-light-100);
  border-radius: 8px;


  input {
    width: 100%;
    border: none;
    @include font-searchbar-regular;
    color: var(--color-dark-500);

    &::placeholder {
      color: #505F79;
    }

    &:focus{
      outline: none;
    }
  }

  span {
    z-index: 1;
  }
}

.icon {
  width: var(--icon-height);
  height: var(--icon-height);

  img {
    width: 100%;
    height: 100%;

    fill: #505F79; //To edit
  }
}