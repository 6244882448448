@import "../../styles/_fonts.scss";

.button {
  // use this CSS variable to override button color from outside
  --btn-base-color: var(--color-accent-300);

  --btn-fg-color: var(--color-light-100);

  --bor-radius: 4px;

  --icon-height: 18px;
  --height: 36px;
  &[data-small] {
    --icon-height: 16px;
    --height: 30px;
    @include font-button-small;
  }

  --left-padding: 16px;
  &[data-lefticon] {
    --left-padding: 12px;
  }

  --right-padding: 16px;
  &[data-righticon] {
    --right-padding: 12px;
  }

  position: relative;

  display: inline-flex;
  align-items: center;
  gap: 8px;
  height: var(--height);
  min-width: 64px;
  padding: 0 var(--right-padding) 0 var(--left-padding);

  @include font-button-regular;

  color: var(--btn-fg-color);
  background: var(--btn-base-color);

  border: 0;
  border-radius: var(--bor-radius);
  cursor: pointer;

  overflow: hidden;
  transition: all .12s ease-out;

  span {
    z-index: 1;
  }

  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }


  // use the "before" element to tint the background
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border-radius: var(--bor-radius);

    opacity: 0;
    transition: all .12s ease-out;
  }

  &:hover::before,
  &:active::before {
    opacity: .1;
  }

  &[data-variant="primary"] {
    &:hover {
      box-shadow: 0 2px 4px rgba(0,0,0,.3);
    }
    &:hover::before {
      background: #fff;
    }
    &:active::before {
      background: #000;
    }
  }

  &[data-variant="secondary"] {
    --btn-base-color: var(--color-dark-500);
    --btn-fg-color: var(--btn-base-color);

    background: var(--color-light-100);
    color: var(--btn-base-color);


    // use the "after" element to tint the border
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .5;

      border-radius: var(--bor-radius);

      box-shadow: inset 0 0 0 1px var(--btn-base-color);
    }

    &:hover::after {
      box-shadow: inset 0 0 0 2px var(--btn-base-color);
    }
    &:active::before {
      background: var(--btn-base-color);
    }
  }

  &[data-variant="tertiary"] {
    --btn-base-color: var(--color-primary-300);
    --btn-fg-color: var(--btn-base-color);

    color: var(--btn-base-color);
    background: none;

    &:hover::before {
      background: var(--color-dark-400);
      opacity: .2;
    }
    &:active::before {
      opacity: .4;
    }
  }
}


// COLOR OVERRIDES
.button[data-color="danger"] {
  --btn-base-color: var(--color-error-300);
}


.icon {
  width: var(--icon-height);
  height: var(--icon-height);

  svg {
    width: 100%;
    height: 100%;

    fill: var(--btn-fg-color);
  }
}
