@import "../../styles/_fonts.scss";

.pill {

  --btn-fg-color: var(--color-dark-500);
  --btn-border-color: #DFE1E6;
  --btn-bg-color: var(--color-light-100);

  padding: 5px 8px;
  color: var(--btn-fg-color);
  border-radius: 16px;
  border: 1px solid var(--btn-border-color);
  background: var(--btn-bg-color);

  user-select: none;

  @include font-pill-button-regular;
}

.pill[data-color="green"] {
  --btn-fg-color: var( --color-success-400);
  --btn-bg-color: var(--color-success-100);
  --btn-border-color: var(--color-success-200);
}

.pill[data-color="blue"] {
  --btn-fg-color: var( --color-primary-300);
  --btn-bg-color: #DEE2FF;
  --btn-border-color: #6973E3;
}

.pill[data-color="red"] {
  // --btn-fg-color: var(--color-dark-500);
  // --btn-bg-color: var(--color-error-300);
  // --btn-border-color: var(--color-error-200);
}

.pill[data-color="yellow"] {
  // --btn-fg-color: var(--color-dark-500);
  // --btn-bg-color: var(--color-error-300);
  // --btn-border-color: var(--color-error-200);
}